import { createRouter, createWebHistory } from "vue-router";

const routes = [
    {
        path: "/ldy",
        name: "ldy",
        component: () => import("../views/ldyView.vue"),
    },
    {
        path: "/ldyd",
        name: "ldyd",
        component: () => import("../views/ldydView.vue"),
    },
    {
        path: "/blank",
        name: "blank",
        component: () => import("../views/BlankView.vue"),
    },
    {
        path: "/addDL",
        name: "addDL",
        component: () => import("../views/AdddlView.vue"),
    },
    {
        path: "/addQd",
        name: "addQd",
        component: () => import("../views/AddqdView.vue"),
    },
    {
        path: "/registerXieYi",
        name: "registerXieYi",
        component: () => import("../views/registerXieYi.vue"),
    },
    {
        path: "/ChildrenView",
        name: "ChildrenView",
        component: () => import("../views/ChildrenView.vue"),
    },
    {
        path: "/PrivacyView",
        name: "PrivacyView",
        component: () => import("../views/PrivacyView.vue"),
    },
    {
        path: "/registerXieYi1",
        name: "registerXieYi1",
        component: () => import("../views/registerXieYi1.vue"),
    },
    {
        path: "/ChildrenView1",
        name: "ChildrenView1",
        component: () => import("../views/ChildrenView1.vue"),
    },
    {
        path: "/PrivacyView1",
        name: "PrivacyView1",
        component: () => import("../views/PrivacyView1.vue"),
    },
    // 渠道端自定义游戏内容
    {
        path: "/customGame",
        name: "customGame",
        component: () => import("../views/customGame.vue"),
    },
    {
        path: "/BoxDownload",
        name: "BoxDownload",
        component: () => import("../views/BoxDownload.vue"),
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
